import React from "react";
import styled from "styled-components";

const StyledBlockQuote = styled.blockquote`
  margin-top: 20px;
  padding: 1.75rem;
  border: 2px solid var(--gold-highlight-color);
  border-radius: 20px;
  line-height: 1.5;
  color: var(--primary-text-color);
  
  a {
    color: var(--blue-highlight-color);
    text-decoration: underline;
  }
`;

const BlockQuote = (props) =>{
    return <StyledBlockQuote>
        {props.children}
    </StyledBlockQuote>
}

export default BlockQuote;