import React from "react";
import styled from "styled-components";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--primary-text-color);
  margin-top: 20px;
  font-size: 0.75rem;
  
  a {
    color: var(--blue-highlight-color);
    text-decoration: underline;
  }
  
  em{
    margin-top: 10px;
  }
`;

const ImageWithCaption = (props)=>{
    return <Container>
           {props.children}
            </Container>
}

export default ImageWithCaption;