import React from "react";
import styled from "styled-components";

const StyledUnorderedList = styled.ul`
  margin-top: 20px;
  color: var(--primary-text-color);
  line-height: 1.5;
  margin-left: 30px;
`;

const UnorderedList = ({children}) => {
    return <StyledUnorderedList>
        {children}
    </StyledUnorderedList>
}

export default UnorderedList;