import React from "react";
import styled from "styled-components";

const StyledParagraph = styled.p`
  color: var(--primary-text-color);
  margin-top: 20px;
  line-height: 1.5;
  a {
    color: var(--blue-highlight-color);
    text-decoration: underline;
  }
`;

const Paragraph = (props)=>{
    return <StyledParagraph>
        {props.children}
    </StyledParagraph>
}

export default Paragraph;