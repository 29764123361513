import React from "react";
import styled from "styled-components";

const StyledUnorderedListItem = styled.li`
    margin-top: 10px;
    list-style-position: inside;

  a {
    color: var(--blue-highlight-color);
    text-decoration: underline;
  }
`;

const UnorderedListItem = ({children}) =>{
    return <StyledUnorderedListItem>
        {children}
    </StyledUnorderedListItem>
}

export default UnorderedListItem;