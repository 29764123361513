import React from "react";
import styled from "styled-components";

const StyledHeading = styled.h1`
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
  color: var(--primary-text-color);  
`;

const BlogTitle = (props) =>{
    return <StyledHeading>
        {props.children}
    </StyledHeading>
}

export default BlogTitle;