import React from "react";
import styled from "styled-components";
import Paragraph from "../components/blog/Paragraph";
import BlogTitle from "../components/blog/BlogTitle";
import ImageWithCaption from "../components/blog/ImageWithCaption";
import BlogHeading2 from "../components/blog/BlogHeading2";
import InternalLink from "../components/blog/InternalLink";
import ExternalLink from "../components/blog/ExternalLink";
import BlockQuote from "../components/blog/BlockQuote";
import UnorderedList from "../components/blog/UnorderedList";
import UnorderedListItem from "../components/blog/UnorderedListItem";
import {StaticImage} from "gatsby-plugin-image";
import SEO from "../components/SEO";

const BlogPostWrapper = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainContent = styled.div`  
  max-width: 750px; 
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BlogPost = ({location}) =>{
    return <BlogPostWrapper> <StyledMainContent>
        <SEO
            title="Essential Pantry Items To Stock Up On"
            description="Here is a list of all the basic, essential items your pantry needs, neatly sorted into categories. Customize them to fit your cooking needs!"
            location={location}
            pageType="article"
        />

        <HeroSection>
            <BlogTitle>
                Essential Pantry Items To Stock Up On
            </BlogTitle>
            <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/essential-pantry-items-to-stock-up-on.jpg" alt="Essential Pantry Items To Stock Up On"/>
                <em>Photo by <ExternalLink to="https://unsplash.com/@hjmckean?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Heather McKean</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/grocery-bill?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
            </ImageWithCaption>
        </HeroSection>
        <Paragraph>
            According to its modern-day definition, a pantry is a room, closet or any type of space where you can store food ingredients you will use in the near (or far) future. A pantry is there so that you don’t have to spend too much time grocery shopping - simply stock up on the things you most frequently use and enjoy preparing your meals without having to go on a grocery run every single day.
        </Paragraph>
        <Paragraph>
            However, it can be difficult to know what exactly you need in your pantry. Perhaps you just moved and are starting from scratch, or you’ve always felt like something was missing from your already stocked shelves.
        </Paragraph>
        <Paragraph>
            This is where our blog post can help! Below is a list of all the basic - essential - items your pantry needs, neatly sorted into categories.
        </Paragraph>
        <Paragraph>
            Of course, no two home cooks (or professional chefs, for that matter) will agree exactly on what a pantry needs to contain. But we hope that our pantry staple lists - carefully put together through research and experimentation - will inspire you to make some changes that will improve your kitchen workflow.
        </Paragraph>
        <BlogHeading2>
            Baking items
        </BlogHeading2>
        <UnorderedList>
            <UnorderedListItem>All-purpose flour</UnorderedListItem>
            <UnorderedListItem>Baking soda</UnorderedListItem>
            <UnorderedListItem>Baking powder</UnorderedListItem>
            <UnorderedListItem>Cornmeal</UnorderedListItem>
            <UnorderedListItem>Cornstarch</UnorderedListItem>
            <UnorderedListItem>Cocoa powder</UnorderedListItem>
            <UnorderedListItem>Baking chocolate</UnorderedListItem>
            <UnorderedListItem>Brown sugar</UnorderedListItem>
            <UnorderedListItem>Powdered sugar</UnorderedListItem>
            <UnorderedListItem>Chocolate chips</UnorderedListItem>
            <UnorderedListItem>Pure vanilla extract</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            With all of these on hand, there’s no telling how many cookies, muffins, breads, cakes, brownies or any other baked good you can make without the need for a grocery trip.
        </Paragraph>
        <BlogHeading2>
            Canned goods
        </BlogHeading2>
        <UnorderedList>
            <UnorderedListItem>Diced tomatoes</UnorderedListItem>
            <UnorderedListItem>Tomato paste</UnorderedListItem>
            <UnorderedListItem>Tomato sauce</UnorderedListItem>
            <UnorderedListItem>Canned tuna (in oil, brine, or spring water, whatever your preference may be)</UnorderedListItem>
            <UnorderedListItem>Chicken stock</UnorderedListItem>
            <UnorderedListItem>Vegetable stock </UnorderedListItem>
            <UnorderedListItem>Canned soup</UnorderedListItem>
            <UnorderedListItem>Canned beans: black beans, white beans, or chickpeas</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Our recommendation is to go for boxed stocks rather than canned ones, as they tend to have better flavor.
        </Paragraph>
        <BlogHeading2>
            Condiments and sauces
        </BlogHeading2>
        <UnorderedList>
            <UnorderedListItem>Mustard (Dijon or yellow)</UnorderedListItem>
            <UnorderedListItem>Salsa</UnorderedListItem>
            <UnorderedListItem>Hot sauce</UnorderedListItem>
            <UnorderedListItem>Ketchup</UnorderedListItem>
            <UnorderedListItem>Mayonnaise</UnorderedListItem>
            <UnorderedListItem>Soy sauce and/or tamari sauce</UnorderedListItem>
            <UnorderedListItem>Basic vinaigrette</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Frozen goods
        </BlogHeading2>
        <UnorderedList>
            <UnorderedListItem>Meat: chicken, sausages</UnorderedListItem>
            <UnorderedListItem>Seafood: thick fish fillets, shrimp</UnorderedListItem>
            <UnorderedListItem>Bread, sliced thickly (for toast)</UnorderedListItem>
            <UnorderedListItem>Frozen veggies: peas, corn, spinach, green beans</UnorderedListItem>
            <UnorderedListItem>Frozen fruit: mango, peaches, berries</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Frozen fruit is especially neat for smoothies and baking.
        </Paragraph>
        <BlogHeading2>
            Grains and starches
        </BlogHeading2>
        <UnorderedList>
            <UnorderedListItem>White rice</UnorderedListItem>
            <UnorderedListItem>Dried pasta (any shape or shapes you like)</UnorderedListItem>
            <UnorderedListItem>Plain bread crumbs</UnorderedListItem>
            <UnorderedListItem>Grain crackers</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Nuts
        </BlogHeading2>
        <UnorderedList>
            <UnorderedListItem>Almonds</UnorderedListItem>
            <UnorderedListItem>Roasted peanuts</UnorderedListItem>
            <UnorderedListItem>Walnuts</UnorderedListItem>
            <UnorderedListItem>Peanut butter (both smooth and crunchy, or your personal preference)</UnorderedListItem>
        </UnorderedList>
        <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/post-2022-7-essential-pantry-items-jars-of-nuts-and-seeds.png" alt="Clear jars of nuts and seeds."/>
                <em>Photo by <ExternalLink to="https://unsplash.com/@maddibazzocco?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Maddi Bazzocco</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/nuts?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"> Unsplash</ExternalLink> </em>
        </ImageWithCaption>
        <BlogHeading2>
            Preserves and pickled food
        </BlogHeading2>
        <UnorderedList>
            <UnorderedListItem>Fruit jams (apricot, strawberry, any other kind you like)</UnorderedListItem>
            <UnorderedListItem>Anchovies</UnorderedListItem>
            <UnorderedListItem>Pickles</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Produce
        </BlogHeading2>
        <UnorderedList>
            <UnorderedListItem>Garlic</UnorderedListItem>
            <UnorderedListItem>Onions</UnorderedListItem>
            <UnorderedListItem>Lemons</UnorderedListItem>
            <UnorderedListItem>All-purpose potatoes</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            You can keep all of these in the pantry - none of the pantry produce items listed here have to be refrigerated.
        </Paragraph>
        <BlogHeading2>
            Spices and dried herbs
        </BlogHeading2>
        <UnorderedList>
            <UnorderedListItem>Salt</UnorderedListItem>
            <UnorderedListItem>Red pepper flakes</UnorderedListItem>
            <UnorderedListItem>Curry powder</UnorderedListItem>
            <UnorderedListItem>Sweet paprika</UnorderedListItem>
            <UnorderedListItem>Bay leaves</UnorderedListItem>
            <UnorderedListItem>Ground cinnamon</UnorderedListItem>
            <UnorderedListItem>Garlic powder</UnorderedListItem>
            <UnorderedListItem>Dried oregano</UnorderedListItem>
            <UnorderedListItem>Ground cayenne</UnorderedListItem>
            <UnorderedListItem>Black peppercorns</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Sweeteners
        </BlogHeading2>
        <UnorderedList>
            <UnorderedListItem>Granulated sugar</UnorderedListItem>
            <UnorderedListItem>Honey</UnorderedListItem>
            <UnorderedListItem>Maple syrup</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Oils and vinegars
        </BlogHeading2>
        <UnorderedList>
            <UnorderedListItem>Extra virgin olive oil</UnorderedListItem>
            <UnorderedListItem>Neutral cooking oil (canola, sunflower seed, or grapeseed oil)</UnorderedListItem>
            <UnorderedListItem>Red wine vinegar</UnorderedListItem>
            <UnorderedListItem>White vinegar (or white wine vinegar)</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Make it right for you
        </BlogHeading2>
        <Paragraph>
            The important thing to keep in mind during the process of restocking your pantry (or building it from the ground up) is that all of the items above are just general guidelines. They are by no means the be-all and end-all of pantry staples.
        </Paragraph>
        <Paragraph>
            If you don’t like peanut butter - don’t buy it! If you prefer brown rice over white rice, go for it. If you’re not a fan of seafood at all, there’s no point in keeping it around the house.
        </Paragraph>
        <Paragraph>
            Personalization and tailoring your pantry to your lifestyle is crucial for reducing food waste and always having the right ingredients on hand. Before you <InternalLink to="/how-to-organize-your-grocery-shopping-list/">put together a shopping list</InternalLink> with everything you need, make sure to analyze each pantry item in this blog and ask yourself whether you will actually use it at any point in the future. This is where meal planning becomes useful.
        </Paragraph>
        <Paragraph>
            If yes, then shop away!
        </Paragraph>
        <BlockQuote>
            A shopping list app, such as  <InternalLink to="/#download-section">Going Shopping</InternalLink> will prove to be of invaluable help when stocking a pantry.
        </BlockQuote>
        <BlogHeading2>
            Keep it organized
        </BlogHeading2>
        <Paragraph>
            One final bit of advice would be to try and organize your <InternalLink to= "/pantry-stock-list/">well stocked pantry</InternalLink> in a way that makes sense for you.
        </Paragraph>
        <Paragraph>
            If you can’t see something, you will likely forget it’s there and will never use it - or worse, you will buy another food item just like it and accidentally store duplicates. This is why shelves, drawers, and slide-out shelves are the most practical for pantries.
        </Paragraph>
        <Paragraph>
            Rectangular containers are best for maximizing available space. A clear, airtight container makes it easy to find what you’re looking for, but you can also label each container. For labeling, you don’t need anything fancier than a roll of painter’s tape and a marker. Though, of course, you are welcome to be more elaborate with your labels.
        </Paragraph>
        <Paragraph>
            The way you will group items on the shelves is completely up to you: some people keep canned goods together with the preserves and pickled food, others prefer to separate them. Some people lump certain nuts with baking items because they’re most frequently used together. You can also opt for grouping the powdered and brown sugars with other sweeteners.
        </Paragraph>
        <Paragraph>
            What matters is that you can easily find your way around the pantry and that you (and your household members) know the general direction of specific ingredient groups.
        </Paragraph>
        <BlogHeading2>
            Bottom line
        </BlogHeading2>
        <Paragraph>
            With our neatly organized list of pantry essentials you can create anything from homemade pizza dough to casseroles to simpler meals such as a meat and a veggie side.
        </Paragraph>
        <Paragraph>
            You can choose to keep it as is, or you can expand on it, adding more items as you learn more recipes or cooking techniques. Either way, you’re off to an excellent start!
        </Paragraph>
        <Paragraph>
            Don’t forget that you can stock up your entire pantry in one shopping trip with the help of our free grocery list app, Going Shopping. <InternalLink to="/#download-section">Download it now!</InternalLink>
        </Paragraph>
    </StyledMainContent>
        </BlogPostWrapper>
}

export default BlogPost;